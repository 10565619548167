angular
    .module('annexaApp')
    .component('annexaBoxTask', {
        templateUrl: './components/tasks/annexa-box-task/annexa-box-task.html',
        controller: ['AnnexaObjectBoxFactory', 'AnnexaEntityFactory', 'TasksModals', 'ModalService', '$rootScope', 'GlobalDataFactory', 'Language', '$scope', '$filter', 'TaskFactory', 'AnnexaFormlyFactory', 'TableFilterFactory', 'HelperService', 'RegFactory', 'TramFactory', 'dialogs', 'RestService', 
        		function (AnnexaObjectBoxFactory, AnnexaEntityFactory, TasksModals, ModalService, $rootScope, GlobalDataFactory, Language, $scope, $filter, TaskFactory, AnnexaFormlyFactory, TableFilterFactory, HelperService, RegFactory, TramFactory, dialogs, RestService) {
            var vm = this;
            var languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASTasks_' + vm.origin) {
                    var id = undefined;
                    
                    if(args.selected && args.selected.length > 0){
                    	vm.search.searchedValue = { id: id, object: args.selected };
                        vm.search.completeAdd();
                    }

                    args.modal.close();
                }
            });


            this.$onInit = function() {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'create_task', 'create_task', vm.isEdit);

                vm.taskModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tasks'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.tasks';
                }

                if(vm.search) {
                    vm.search.origin = 'tasks';
                    vm.search.type = 'Task';
                    vm.search.customFilter = '{ "taskNumber": "@VAL@", "description": "@VAL@"}';
                    vm.search.additionalFilter = { finished: false }
                    if (vm.origin) {
                        switch (vm.origin) {
	                        case 'annexa-box-object-reg':
	                        	vm.search.additionalFilter.assignedRegisterEntry = true;
	                            break;
	                        case 'annexa-document':
	                        	vm.search.additionalFilter.assignedDoc = true;
	                            break;
	                        case 'annexa-dossier-transaction-tasks':
	                        	vm.search.additionalFilter.assignedCertificated = true;
	                            break;
	                        case 'task-task':
	                        case 'subtask-task':
	                        case 'duplicated-task':
	                        case 'third-task':
	                        	vm.search.additionalFilter.assignedTask = true;
	                            break;
	                    }
                    	
                    }
                    
                    vm.search.searchFunc = function(val) {
                        var added = $linq(vm.content).select(vm.search.addedSelect).toArray();

                        if (vm.entityId) {
                        	added.push(vm.entityId);
                        }

                        var filter = { name: val.val };

                        if(vm.search.customFilter) {
                            var pareseableCustomFilter = vm.search.customFilter.replace('@VAL@', val.val);
                            pareseableCustomFilter = pareseableCustomFilter.replace('@VAL@', val.val);
                            filter = JSON.parse(pareseableCustomFilter);
                        }

                        var additionalFilter = vm.search.additionalFilter ? vm.search.additionalFilter : {};
                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                        additionalFilter.added = added;
                        additionalFilter.langColumn = Language.getActiveColumn();

                        return RestService.filterData(vm.search.origin, vm.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
                            .then(function (data) {
                                var response = [];

                                _.forEach(data.data.content, function(value, key) {
                                    response.push({
                                        id: value.id,
                                        object: value,
                                        value: vm.search.processSearch(value)
                                    });
                                });

                                response = new SortedArray(response, 'value').sort();

                                return response;
                            });

                    }
                    
                    vm.search.processSearch = function (object) {
                        var assigned = '';

                        if(object.user) {
                            assigned += object.user.name + ' ' + object.user.surename1 + (object.user.surename2 ? ' ' + object.user.surename2 : '') ;
                        }
                        if(object.profile) {
                            assigned += object.profile[languageColumn];
                        }

                        return object.description + (assigned ? ' (' + assigned + ')' : '');
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                            var confirmSubtasks = function(tasksToConfirm) {
                                var confirmBody = '';
                                confirmBody = $filter('translate')('global.tasks.literals.assignTasksConfirm');
                                confirmBody += '<br/><br/><ul>';
                                _.forEach(tasksToConfirm, function (item) {
                                    confirmBody += '   <li>' + item.taskNumber + ' - ' + item.description + '</li>';
                                });
                                confirmBody += '</ul><br/>';

                                var dlg = dialogs.confirm(
                                	vm.origin == 'duplicated-task' ? $filter('translate')('global.tasks.literals.duplicatedTasks') : $filter('translate')('global.tasks.literals.subtasks'),
                            		confirmBody,
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: '',
                                        backdropClass: 'zMax',
                                        windowClass: 'zMax2'
                                    }
                                );
                                
                                dlg.result.then(function (btn) {
                            		_.forEach(tasksToConfirm, function(task){
                            			vm.content.push(task);
                            		});
                            		
                        			vm.search.searchedValue = { id: -1, value: '' };
                        			if(vm.search.saveSearch) {
                        				vm.search.saveSearch(newContent);
                        			}
                                }, function (btn) {
                                	var newContentExceptNotConfirmed = $linq(newContent).except(tasksToConfirm, "(x, y) => x.id == y.id ").toArray();
                                	
                                	vm.search.searchedValue = { id: -1, value: '' };
                        			if(vm.search.saveSearch) {
                        				vm.search.saveSearch(newContentExceptNotConfirmed);
                        			}
                                });
                            }
                            
                        	var newContent = [];
                        	if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push(obj);
                        		});
                        	}else{
                        		newContent.push(vm.search.searchedValue.object);
                        	}

                        	if (vm.origin == 'subtask-task' || vm.origin == 'duplicated-task') {
                        		var newContentToConfirm = [];
                        		
                        		_.forEach(newContent, function(obj){
                        			if (obj.parentTask) {
                        				newContentToConfirm.push(obj);
                                	} else {
                                		vm.content.push(obj);
                                	}
                        		});
                        		
                        		if (newContentToConfirm.length > 0) {
                        			confirmSubtasks(newContentToConfirm, newContent);
                        		} else {
                        			vm.search.searchedValue = { id: -1, value: '' };
                        			if(vm.search.saveSearch) {
                        				vm.search.saveSearch(newContent);
                        			}
                        		}
                        	} else {
                        		_.forEach(newContent, function(obj){
                        			vm.content.push(obj);
                                });
                        		
                        		vm.search.searchedValue = { id: -1, value: '' };
                                if(vm.search.saveSearch) {
                                    vm.search.saveSearch(newContent);
                                }
                        	}
                        }
                    }

                    vm.search.advancedSearch = function() {
                        var expiredStates = [
                            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
                            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
                        ];

                        var getSpecificStatuses = function (globalStatusList) {
                            var specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).distinctBy("x => x." + languageColumn).orderBy("x => x." + languageColumn, linq.caseInsensitiveComparer).toArray();
                            
                            if (globalStatusList && globalStatusList.length > 0) {
                            	specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).where(function(status){
            	        			return $linq(globalStatusList).contains(status.globalStatus, function(x,y){
            	        				if(x && x.id && y == x.id){
            	        					return true;
            	        				}else{
            	        					return false;
            	        				}
            	        			});
                        		}).distinctBy("x => x." + languageColumn).orderBy("x => x." + languageColumn, linq.caseInsensitiveComparer).toArray();
                            }

                            return HelperService.addAllSelect(angular.copy(specificStatuses), languageColumn, languageColumn);
                        }

                        var filterAdvancedSearchModal = [
                            { id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true},
                            { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', callAux: true },
                            { id: 'type', type: 'select', order: 2, label: 'global.literals.taskType', dataType: 'LOCAL', data: GlobalDataFactory.taskTypes, addAll: true, nameProperty: languageColumn, callAux: true },
                            //Altres criteris
                            { id: 'createdUser', type: 'text', order: 0, label: 'global.literals.started_for', callAux: true, userChoosen: true },
                            { id: 'user', type: 'text', order: 1, label: 'global.literals.assigned_to', callAux: true, userChoosen: true },
                            { id: 'startDates', type: 'dateRange', order: 2, label: 'global.literals.startDate', callAux: true, userChoosen: true },
                            { id: 'endDates', type: 'dateRange', order: 3, label: 'global.literals.endDate', callAux: true, userChoosen: true },
                            { id: 'expired', type: 'select', order: 4, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: expiredStates, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true },
                            { id: 'status', type: 'select-multiple', order: 5, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: TaskFactory.taskStatuses, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true },
                            { id: 'specificStatus', type: 'select-multiple', order: 6, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: languageColumn, callAux: true, userChoosen: true },
                            { id: 'thirdName', type: 'text', order: 7, label: 'global.thirds.literals.third', callAux: true, userChoosen: true },
                            { id: 'address', type: 'text', order: 8, label: 'global.territory.list.address', callAux: true, userChoosen: true },
                            { id: 'meta', type: 'text', order: 9, label: 'global.literals.meta', callAux: true, userChoosen: true }
                        ];
                        
                        var getAddedFunction = function() {
                        	var added = $linq(vm.content).select("x => x.id").toArray();

                        	if (vm.entityId) {
                            	added.push(vm.entityId);
                            }
                        	
                        	return added;
                        }
                        
                        //Definim una funció pròpia perquè ens interesa carregar el desplegable dels estats específics de forma dinàmica
                        var getFilterCallAux = function(){
                            var filterCallAux = TableFilterFactory.getFilterCallAux(filterAdvancedSearchModal);
                            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                            filterCallAux.langColumn = languageColumn;
                            
                            filterCallAux.added = getAddedFunction();
                            filterCallAux.assignedTask = true;
                            
                            if (advancedSearchModal) {
                        		var filterSpecificStatusAux = $linq(advancedSearchModal.filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
                   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);

                   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
                   					var specificListFilter = [];
                   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
                   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
                   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
                   	   					})) {
                   	   						specificListFilter.push({id: value[languageColumn]});
                   	   					}
                   	                });

                   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
                   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
                	        			return $linq(specificListFilter).contains(specificStatus[languageColumn], function(x,y){
                	        				if(x && x.id && y == x.id) { return true; } else { return false; }
                	        			});
                   	                }).toArray();
                   	                
                   	                filterCallAux.specificStatus = specificListFilter;
                        		} else {
                    				filterSpecificStatusAux.model = undefined;
                        		}
                            }

                        	return filterCallAux;
                        };
                        
                        var advancedSearchModal = {
                            title: 'global.literals.advanced_search_tasks',
                            size: 'modal-lg',
                            filter: filterAdvancedSearchModal,
                            tableDefinition: {
                                id: 'tableAdvancedSearchTasks',
                                origin: 'tasks',
                                objectType: 'Task',
                                sortName: 'taskNumber',
                                sort: [[1, 'asc']],
                                filterCallAux: getFilterCallAux(),
                                filterCallAuxFunc: getFilterCallAux,
                                columns: [
                                    { id: 'taskNumber', title: $filter('translate')('global.literals.code') },
                                    { id: 'description', title: $filter('translate')('global.literals.task_name') },
                                    { id: 'type.' + languageColumn, title: $filter('translate')('global.literals.taskType') }
                                ],
                                noLoading: true,
                                skipFirstSearch: true
                            },
                            multiple: true
                        }
                        
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASTasks_' + vm.origin, advancedSearchModal);
                    }

                }

                if(vm.new) {
                    vm.new.newFunc = function() {
                        var proccesNew = function(item, form) {
                            vm.search.searchedValue = { id: item.id, object: JSOG.decode(item) }
                            $rootScope.$broadcast('annexaObjectBoxSearchAdd');
                            form.close();
                        }

                        var modal = angular.copy(TasksModals.taskNew);
                        var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getTaskBoxNewForm(modal, proccesNew), AnnexaEntityFactory.taskBoxes());

                        if(vm.entryType && vm.isEdit){
                            if(vm.entryType == "INPUT" ){
                                entity.boxes[2].boxDefinition.content.push(RegFactory.regEntry);
                            }else if(vm.entryType == "OUTPUT"){
                                entity.boxes[3].boxDefinition.content.push(RegFactory.regEntry);
                            }
                        }
                        if(vm.config.isTramBox){
                            vm.config.isTramBox.dossier = {id: TramFactory.Dossier.id, dossierNumber: TramFactory.Dossier.dossierNumber, endDate: TramFactory.Dossier.endDate, closed: TramFactory.Dossier.closed, subject: TramFactory.Dossier.subject, procedure:{id: ((TramFactory.Dossier.procedure)? TramFactory.Dossier.id : ""), procedureViewProfiles: ((TramFactory.Dossier.procedureViewProfiles)? TramFactory.Dossier.procedureViewProfiles : [])}};
                            entity.boxes[1].boxDefinition.content.push(vm.config.isTramBox);
                        }
                        
                        modal.annexaFormly = entity.form;
                        modal.boxes = entity.boxes;
                        modal.submitModal = modal.annexaFormly.onSubmit;
                        modal.alerts = [];
                        entity.modal = modal;

                        ModalService.showModal({
                            template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                            controller: 'AnnexaFormlyModalController',
                            modalObj: entity
                        }).then(function(modalThen) {
                            modal.modal = modalThen;
                            modal.close = modalThen.element.scope().close;
                            modal.annexaFormly.close = modalThen.element.scope().close;
                            modalThen.element.modal();
                            angular.element(".modal-title").focus();
                        });

                        //endregion
                    }
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@',
            entityId: '=?'
        }
    })
    .component('annexaBoxTaskRender', {
        templateUrl: './components/tasks/annexa-box-task/annexa-box-task-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'HelperService', 'TaskFactory', 'Language', function(DialogsFactory, $rootScope, HelperService, TaskFactory, Language) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            
            var getMaxDays = function(task){
                var holidays = [];
                var workinDays = 0;

                if($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
                    holidays = $rootScope.LoggedUser.entity.holidays;
                }

                workinDays = HelperService.getWorkingDays(new Date(task.createdDate), new Date(), holidays);

                return task.days - workinDays;
            }

            vm.getClass = function(task) {
                return HelperService.getWorkingDaysClass(getMaxDays(task));
            }

            vm.getLabel = function(task) {
                var maxDays = getMaxDays(task);

                if(maxDays > 5) {
                    return '+' + maxDays;
                } else {
                    return maxDays;
                }
            }

            vm.getClassStatus = function(task) {
                var content = '';

                if(TaskFactory.taskStatuses && TaskFactory.taskStatuses.length > 0) {
                    var status = $linq(TaskFactory.taskStatuses).singleOrDefault(undefined, "x => x.id == '" + task.status.globalStatus + "'");

                    if(status) {
                        content += 'fa-' + status.icon + ' ' + status.style + ' fa-lg fa-stack-1x';
                    }
                }

                return content;
            }
            
            vm.delete = function(index) {
            	var titleDeleteMessage = '';
            	var confirmDeleteMessage = '';
            	
            	if (vm.origin == 'subtask-task') {
            		titleDeleteMessage = 'global.task.subtask.deleteMessage';
            		confirmDeleteMessage = 'global.task.subtask.deleteMessageConfirm';
            	} else if (vm.origin == 'duplicated-task') {
            		titleDeleteMessage = 'global.task.duplicated.deleteMessage';
            		confirmDeleteMessage = 'global.task.duplicated.deleteMessageConfirm';
            	} else {
            		titleDeleteMessage = 'global.literals.delete';
            		confirmDeleteMessage = 'global.literals.deleteTaskBox';
            	}
            	
            	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxTasksDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })