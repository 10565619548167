/**
 * Created by amotis on 16/12/2020.
 */
angular
    .module('annexaApp')
    .component('annexaTaskHistorical', {
        templateUrl: './components/tasks/annexa-task-historical/annexa-task-historical.html',
        controller: ['$rootScope', '$filter', '$scope', 'Language', function ($rootScope, $filter, $scope, Language) {
            var vm = this;

            vm.actionTypesList = [
                {id: '', name: 'global.literals.all'},
                {id: 'STATUS_CHANGES', name: 'global.tasks.historical.actionType.STATUS_CHANGES'},
                {id: 'PARENT_LINKED', name: 'global.tasks.historical.actionType.PARENT_LINKED'},
                {id: 'PARENT_REMOVED', name: 'global.tasks.historical.actionType.PARENT_REMOVED'},
            ]

            vm.actionType = '';

            vm.filterHistorical = function(item) {
                if(!item.id) {
                    vm.filterData = { task: { id: vm.task.id } };
                } else {
                    vm.filterData = { task: { id: vm.task.id }, actionType: item.id };
                }

                vm.tableDefinition.reloadInternalData(true, true);
            }
            
            if(vm.task){
                vm.filterData = { task: { id: vm.task.id } };
            }

            var getFilterCall = function() {
                return vm.filterData;
            }

            var getFilterCallAux = function() {
                return {};
            }

            var actionRender = function(data, type, object, meta) {
            	if (object.parentTask) {
            		return $filter('translate')('global.tasks.historical.actionType.' + data) 
            			+ ' <i class="fa fa-sort-down" aria-hidden="true"></i><br>' 
            			+ (object.parentRelation ? $filter('translate')('global.tasks.historical.subtask') : $filter('translate')('global.tasks.historical.duplicatedTask'))
            			+ ' de ' + object.parentTask.description;
            	} else {
            		return $filter('translate')('global.tasks.historical.actionType.' + data) 
            			+ ' <i class="fa fa-sort-down" aria-hidden="true"></i><br>'
            			+ object.oldStatus[Language.getActiveColumn()] + ' a ' + object.newStatus[Language.getActiveColumn()];
            	}
                
            };

            vm.getUserName = function(user) {
                if(user) {
                    return user.name + ' ' + user.surename1 + (user.surename2 ? ' ' + user.surename2 : '');
                }
            }

            vm.tableDefinition = {
                id: 'bottom-historical',
                lengthChange: false,
                compileHeader: false,
                showTopButtons: false,
                dom: 'rt<"row"<"col-sm-6"i><"col-sm-6"p>>',
                customDom: true,
                origin: 'tasks',
                objectType: 'TaskHistorical',
                sortName: 'createdDate',
                sort: [[1, 'desc']],
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: [
                    { id: 'actionType', title: $filter('translate')('global.literals.action'), render: actionRender },
                    { id: 'createdDate', column: new DateTimeColumn($filter, 'global.literals.datetime') },
                    { id: 'createdUser', column: new UserColumn($filter, 'global.literals.user') }
                ],
                containerScope: $scope
            }
        }],
        bindings: {
        	task: '='
        }
    });